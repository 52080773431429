div.ActivityList {
  background-color: #d9d9d9;
  margin: 24px 24px;
  padding: 24px;
  border-radius: 8px;

  h2 {
    margin: 0;
  }

  div.list {
    div.item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      background-color: white;
      padding: 10px 14px;
      border-radius: 8px;
      margin: 16px 0;

      animation: popIn 500ms;

      div.date,
      div.kind {
        opacity: 0.5;
        font-size: 14px;
      }

      div.name,
      div.pints {
        font-weight: bold;
        font-size: 22px;
        margin-top: -2px;
        color: #4267b2;
      }

      div.date {
        grid-column: 1;
        grid-row: 1;
      }

      div.name {
        grid-column: 1;
        grid-row: 2;
      }

      div.pints {
        grid-column: 2;
        grid-row: 2;
        text-align: right;
      }

      div.kind {
        grid-column: 2;
        grid-row: 1;
        text-align: right;
      }
    }
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  50% {
    transform: scale(1.1) translateY(-5px);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}