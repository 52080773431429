div.Kitchen {
  div.header {
    display: flex;
    place-items: center;
    place-content: center;
    gap: 32px;
    padding: 24px;

    img {
      height: 72px;
    }

    h1 {
      font-size: 36px;
    }
  }

  div.addButton {
    display: flex;
    place-items: center;
    place-content: center;
    position: fixed;
    bottom: 32px;
    right: 32px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #4267b2;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);

    div {
      transition: transform 150ms;
      color: white;
      font-size: 64px;
      transform: translateY(-4px);

      &.iconClose {
        transform: rotate(45deg) translateY(-4px);
      }
    }
  }
}