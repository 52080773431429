div.SignIn {
  text-align: center;
  padding: 0 24px;

  div.header {
    display: flex;
    place-items: center;
    place-content: center;
    gap: 32px;
    padding: 24px;

    img {
      height: 72px;
    }

    h1 {
      font-size: 36px;
    }
  }

  button {
    display: inline-block;
    margin-bottom: 24px;
    font-size: 24px;
    font-family: Inter;
    font-weight: bold;
    background-color: #4267b2;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px 12px;
    outline: none;
  }
}