div.Graph {
  background-color: #d9d9d9;
  margin: 0 24px;
  border-radius: 8px;
  padding-top: 0.1px;

  div.bar {
    display: flex;
    margin: 32px 0;

    div.mainBar {
      display: flex;
      place-items: center;
      background-color: #4267b2;
      border-radius: 0 8px 8px 0;
      height: 48px;

      div.name {
        width: 100%;
        text-align: right;
        font-weight: 700;
        color: white;
        margin: 0 12px;
      }
    }

    div.pints {
      display: flex;
      place-items: center;
      margin-left: 12px;
      width: 48px;
      font-weight: bold;
    }
  }

  div.expandButton {
    text-align: center;
    padding-bottom: 16px;
    opacity: 0.5;
  }
}