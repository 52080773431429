div.App {
  >div.header {
    display: flex;
    place-items: center;
    place-content: center;
    gap: 32px;
    padding: 24px;

    img {
      height: 72px;
    }

    h1 {
      font-size: 36px;
    }
  }

  >div.kitchenList {
    background-color: #d9d9d9;
    margin: 24px 24px;
    padding: 24px 24px 12px 24px;
    border-radius: 8px;

    h2 {
      margin: 0;
    }

    div.list {
      div.item {
        background-color: white;
        padding: 10px 14px;
        border-radius: 8px;
        margin: 16px 0;
        cursor: pointer;

        div {
          font-weight: bold;
          font-size: 22px;
          color: #4267b2;
        }
      }
    }
  }
}