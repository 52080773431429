div.AddMenu {
  display: flex;
  place-items: center;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);

  &.invisible {
    opacity: 0;
    pointer-events: none;
  }

  div.inner {
    display: flex;
    flex-direction: column;
    place-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 24px 16px;
    width: 70%;

    input,
    select,
    button {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 24px;
      width: 80%;
      box-sizing: border-box;
      margin: 12px 0;
      font-family: Inter;
    }

    button {
      background-color: #4267b2;
      color: white;
      font-weight: bold;
      border: none;
    }
  }
}